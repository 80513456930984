function showPopup(e, selection, formId) {
  e.preventDefault();

  hbspt.forms.create({
    region: "na1",
    portalId: "5320190",
    formId: formId,
    target: ".hubspot-form-container",
    onFormReady: function ($form) {
      $form.find("select").val(selection).change();
      document.getElementById("popup").classList += " show";
    },
  });
}
function hidePopup(e) {
  e.preventDefault();
  document.getElementById("popup").classList -= " show";
}

document.addEventListener("DOMContentLoaded", function () {
  function showPopuptwo(selection) {
    hbspt.forms.create({
      region: "na1",
      portalId: "5320190",
      formId: "82478db9-fad8-4cf0-99d4-1ade5699d5d6",
      target: ".hubspot-form-container",
      onFormReady: function ($form) {
        $form.find("select").val(selection).change();
        document.getElementById("popup").classList += " show";
      },
    });
  }
  function hidePopuptwo() {
    document.getElementById("popup").classList -= " show";
  }

  /*$("#showpopuptwo").click(function () {
    showPopuptwo();

  });
  */

  $("#showpopuptwo").click(function () {
    window.location.href = '/newsletter-signup';
});

  $("#hideit").click(function () {
    hidePopuptwo();
  });
});
