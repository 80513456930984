$(function () {
  if ($("#js-subnavbar").length) {
    var $subnavbar = $("#js-subnavbar");
    var subnavbarOffsetTop = $subnavbar.offset().top;
    var subnavbarBounding = $("#js-hellobar").length
      ? subnavbarOffsetTop - 48
      : subnavbarOffsetTop;
  }

  if ($("#js-navbar").length) {
    var $navbar = $("#js-navbar");
    var navbarOffsetTop = $navbar.offset().top;
    var navbarBounding = $("#js-hellobar").length
      ? navbarOffsetTop - 48
      : navbarOffsetTop;
  }

  // Scrolled nav bars
  function onScroll() {
    if ($("#js-subnavbar").length) {
      const scrollTop = $(window).scrollTop();
      let $navItemLinks = $(".nav-item-link");
      let $subnavbarNav = $("#subnavbarCollapse");
      let $subnavbarSignupBtn = $("#js-subnavbar-signup-btn");
      let $subnavbarPricingLink = $("#js-subnavbar-pricing-link");
      let $subnavbarPricingBtn = $("#js-subnavbar-pricing-btn");

      if (subnavbarBounding < scrollTop) {
        $navItemLinks.removeClass("d-none");

        $subnavbar.addClass("subnavbar--scrolled");
        $subnavbarNav.addClass("justify-content-end");

        $subnavbarSignupBtn
          .removeClass("d-lg-none btn-dark")
          .addClass("btn-success");

        $subnavbarPricingLink.addClass("d-none");

        $subnavbarPricingBtn.removeClass("d-lg-none btn-dark");
      } else {
        $navItemLinks.addClass("d-none");

        $subnavbar.removeClass("subnavbar--scrolled");
        $subnavbarNav.removeClass("justify-content-end");

        $subnavbarSignupBtn
          .removeClass("btn-success")
          .addClass("d-lg-none btn-dark");

        $subnavbarPricingLink.removeClass("d-none");

        $subnavbarPricingBtn.addClass("d-lg-none btn-dark");
      }
    }

    if ($("#js-navbar").length && !$("#js-subnavbar").length) {
      const scrollTop = $(window).scrollTop();

      if (navbarBounding < scrollTop) {
        $navbar.addClass("navbar--scrolled");
      } else {
        $navbar.removeClass("navbar--scrolled");
      }
    }
  }

  window.addEventListener("scroll", function () {
    onScroll();
  });

  $(document).on(
    "click.bs.dropdown.data-api",
    ".dropdown.keep-inside-clicks-open",
    function (e) {
      e.stopPropagation();
    }
  );

  $('a:not(.dropdown-toggle)[href="#"]').css({
    boxShadow: "0 0 0 3px red",
  });

  onScroll();

  // Style navbar on mobile when opened
  $(".navbar-collapse")
    .on("show.bs.collapse", function () {
      $("#js-navbar").addClass("navbar--opened");
    })
    .on("hidden.bs.collapse", function () {
      $("#js-navbar").removeClass("navbar--opened");
    });

  // Tooltips
  $('[data-toggle="tooltip"]').tooltip();

  // Smooth Scroll
  if ($(".js-smooth-scroll").length) {
    $(".js-smooth-scroll").on("click", function (e) {
      let scrollTop = $($(this).attr("href")).offset().top;

      $("html, body").stop().animate(
        {
          scrollTop: scrollTop,
        },
        500
      );

      return e.preventDefault();
    });
  }
});

window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}
gtag("js", new Date());
gtag("config", "UA-24698915-1");
