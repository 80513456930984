const main = () => {
  if (location.pathname.startsWith("/blog")) {
    const params = new URLSearchParams(location.search);
    if (params.has("page")) {
      const pageNum = parseInt(params.get("page"));
      if (Number.isInteger(pageNum)) {
        // location.replace(`${location.pathname}/page/${pageNum}`);
        const el = document.createElement("meta");
        el.setAttribute("http-equiv", "refresh");
        el.setAttribute(
          "content",
          `0;url=${location.pathname}/page/${pageNum}`
        );
        document.head.prepend(el);
      }
    }
  }
};

main();
