function showZeroCase() {
  var SearchSection = $(".js-search-section").length;
  var SearchSectionNone = $(".js-search-section.d-none").length;

  $(".js-search-zero-case").addClass("d-none");

  if (SearchSection === SearchSectionNone) {
    $(".js-search-zero-case").removeClass("d-none");
  }
}

$(".js-search-form").submit(function (e) {
  e.preventDefault();
});

$(".js-search-input").on("keyup", function () {
  var searchValue = $(this).val();
  var $searchEl = $(".js-search-element");

  $searchEl.closest(".js-search-list-item").addClass("d-none");
  $searchEl.closest(".js-search-section").addClass("d-none");

  if ($(this).val() !== "") {
    $searchEl.each(function () {
      if (
        searchValue != "" &&
        $(this).text().search(new RegExp(searchValue, "gi")) != -1
      ) {
        $(this).closest(".js-search-list-item").removeClass("d-none");
        $(this).closest(".js-search-section").removeClass("d-none");
      }
    });
  } else {
    $(".js-search-list-item").removeClass("d-none");
    $(".js-search-section").removeClass("d-none");
  }

  showZeroCase();
});
