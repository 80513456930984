// Pricing form
if ($("#js-pricing-form").length > 0) {
  $("#js-pricing-form").submit(function (e) {
    return false;
  });
}

// Pricing calculator

if ($("#js-pricing-calculator").length > 0) {
  var memberCount = $("#js-pricing-member-input").val() || 0;
  var resourceCount = $("#js-pricing-resource-input").val() || 0;
  var seatCount = $("#js-pricing-seat-input").val() || 0;

  var dollarPrice = {
    80: "150",
    150: "194",
    160: "199",
    170: "210",
    180: "220",
    190: "231",
    200: "241",
    210: "252",
    220: "262",
    230: "273",
    240: "283",
    250: "294",
    260: "304",
    270: "315",
    280: "325",
    290: "336",
    300: "346",
    310: "357",
    320: "372",
    330: "382",
    340: "393",
    350: "403",
    360: "414",
    370: "424",
    380: "430",
    390: "435",
    395: "440",
    450: "472",
    500: "525",
    750: "630",
    1000: "683",
    2000: "913",
    3000: "1050",
    4000: "1386",
    5000: "1470",
  };

  var euroPrice = {
    80: "150",
    150: "194",
    160: "199",
    170: "210",
    180: "220",
    190: "231",
    200: "241",
    210: "252",
    220: "262",
    230: "273",
    240: "283",
    250: "294",
    260: "304",
    270: "315",
    280: "325",
    290: "336",
    300: "346",
    310: "357",
    320: "372",
    330: "382",
    340: "393",
    350: "403",
    360: "414",
    370: "424",
    380: "430",
    390: "435",
    395: "440",
    450: "472",
    500: "525",
    750: "630",
    1000: "682",
    2000: "913",
    3000: "1050",
    4000: "1386",
    5000: "1470",
  };

  var poundPrice = {
    80: "125",
    150: "141",
    160: "152",
    170: "162",
    180: "173",
    190: "183",
    200: "194",
    210: "204",
    220: "215",
    230: "225",
    240: "236",
    250: "246",
    260: "257",
    270: "267",
    280: "278",
    290: "283",
    300: "288",
    310: "294",
    320: "299",
    330: "304",
    340: "309",
    350: "315",
    360: "320",
    370: "325",
    380: "330",
    390: "336",
    395: "341",
    400: "346",
    500: "420",
    750: "483",
    1000: "525",
    2000: "630",
    3000: "787",
    4000: "892",
    5000: "1050",
  };

  var allPrices = {
    euro: euroPrice,
    dollar: dollarPrice,
    pound: poundPrice,
  };

  var allCurrencies = {
    euro: "€",
    dollar: "$",
    pound: "£",
  };

  updatePrice();
  updateUsers();

  function updatePrice() {
    var currency = $("#js-price-currency-select").val();
    var prices = allPrices[currency];
    var cappedPrice = $("#js-pricing-capped-price");
    var priceMonth = $("#js-price-month");
    var priceWorkplan = $("#js-price-workplace-month");
    var priceCurrency = $("#js-price-currency");

    memberMax = 500;

    updateMaxUsers(memberMax);

    //Workplace pricing (25 per room + 0.5 per seat)
    var price =
      parseInt(resourceCount) * 25 + Math.ceil(parseInt(seatCount) / 2);
    if (price < 150) price = 150;
    priceWorkplan.html(price);

    //Coworking prices
    if (memberCount > 0) {
      if (memberCount <= memberMax) {
        cappedPrice.css("opacity", "0").hide();
        priceCurrency.show();
        for (var key in prices) {
          if (memberCount <= parseInt(key)) {
            priceMonth.html(prices[key]);
            return;
          }
        }
      } else {
        priceMonth.html(prices[501]);
        cappedPrice.css("opacity", ".9").show();
        priceCurrency.hide();
      }
    }
  }

  function updateCurrency() {
    var currency = $("#js-price-currency-select").val();
    var currencySymbol = allCurrencies[currency];
    $("#js-price-currency, .js-price-currency").html(currencySymbol);
  }

  function updateUsers() {
    $("#js-pricing-users-count").text($("#js-pricing-member-input").val());
    $("#js-pricing-seat-count").text($("#js-pricing-seat-input").val());
    $("#js-pricing-resource-count").text($("#js-pricing-resource-input").val());
  }

  function updateMaxUsers(memberMax) {
    $("#js-max-users").text(memberMax);
  }

  function updatePriceAndCurrency() {
    updatePrice();
    updateCurrency();
    updateUsers();
  }

  function updatePricing() {
    memberCount = $("#js-pricing-member-input").val() || 0;
    seatCount = $("#js-pricing-seat-input").val() || 0;
    resourceCount = $("#js-pricing-resource-input").val() || 0;

    updatePriceAndCurrency();
  }

  $("#js-price-currency-select").bind(
    "change keyup mouseup",
    updatePriceAndCurrency
  );
  $(
    "#js-pricing-member-input,#js-pricing-seat-input,#js-pricing-resource-input"
  ).bind("input", updatePricing);

  var euCountries = [
    "BE",
    "EL",
    "LT",
    "PT",
    "BG",
    "ES",
    "LU",
    "RO",
    "CZ",
    "FR",
    "HU",
    "SI",
    "DK",
    "HR",
    "MT",
    "SK",
    "DE",
    "IT",
    "NL",
    "FI",
    "EE",
    "CY",
    "AT",
    "SE",
    "IE",
    "LV",
    "PL",
  ];
  var lang = navigator.language.toLocaleLowerCase();

  if (lang == "en-gb") {
    $("#js-price-currency-select").val("pound");
  } else if (
    euCountries.find(function (iso) {
      return lang.startsWith(iso.toLocaleLowerCase());
    }).length > 0
  ) {
    $("#js-price-currency-select").val("euro");
  } else $("#js-price-currency-select").val("dollar");

  updatePriceAndCurrency();
}
