function playVideo(videoId) {
  $("#js-video-player").attr(
    "src",
    "https://www.youtube.com/embed/" +
      videoId +
      "?autoplay=1&rel=0&wmode=transparent"
  );
}

var $video = $(".js-video");

$video.on("click", function (event) {
  var videoId = $(this).data("videoid");
  var videoName = $(this).find(".js-videos-list-title").text();

  $(".js-video").removeClass("active");
  $(this).addClass("active");

  $(".js-videos-subtitle").text(videoName);

  playVideo(videoId);

  event.preventDefault();
});
