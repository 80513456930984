var $filtersLink = $(".js-filters-link");
var $filtersLinkAll = $(".js-filters-all");
var hash = window.location.hash;

function markNavActive(el) {
  $filtersLink.parent("li").removeClass("active");
  el.parent("li").addClass("active");
}

function toggleConsultants(el) {
  $(".js-consultants-list-item").hide();
  $(el).show();
}

function filterByHash(hash) {
  var filterClass = ".js-filter-" + hash.substr(1);
  var navActiveEl = $('a[href="' + hash + '"]');
  var offset = $("#js-filters").offset();

  var scrollTop = (offset ? offset : 0).top;

  toggleConsultants(filterClass);
  markNavActive(navActiveEl);

  $("html, body").stop().animate(
    {
      scrollTop: scrollTop,
    },
    500
  );
}

function filterByLink() {
  $filtersLink.on("click", function () {
    var filtersLinkUrl = $(this).attr("href");
    var filterClass = ".js-filter-" + filtersLinkUrl.substr(1);

    toggleConsultants(filterClass);
    markNavActive($(this));
  });
}

function filterByAllLink() {
  $filtersLinkAll.on("click", function () {
    $(".js-consultants-list-item").show();
    markNavActive($(this));
  });
}

filterByLink();
filterByAllLink();

if (hash !== "" && hash !== "#all") {
  filterByHash(hash);
}
